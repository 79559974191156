@import "../../styles/mixin";
@import "../../styles/variables";

.what-do-we-do {
    background-color: $AmarilloBanner;
    display: flex;
    flex-direction: column;
    grid-row: 3;
    gap: 34px;
    height: 330px;
    border-bottom: solid $BorderWidth $BorderBlack;;
    border-top: solid $BorderWidth $BorderBlack;;
    .title {
        display: flex;
        flex-direction: column;
        padding-top: 17px;
        text-align: center;

        margin-bottom: 12px;
        @include textBasis($text);
        :first-child {
            text-align: center;
            @include textBasisBold($title);
        }
    }
    .cards-wrapper {
        position: absolute;
        margin-top: 90px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
        margin-bottom: -50px;
        .card {
            width: 275px;
            background-color: #ffffff;
            box-shadow: 0px 23px 35.7px 2.3px rgba($BorderBlack, 0.15);
            border-radius: $borderRadiusMain;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0;
            text-align: center;
            opacity: 0;
            margin-top: 80px;
            border: solid $BorderWidth $BorderBlack;
            text-decoration: none;
            transition: all ease 0.3s;
            &.animation{
                animation: UpSequence 0.4s linear forwards;
            }
            &:hover{
                transition: all ease 0.3s;
                transform: scale(1.02);
                box-shadow: 0px 23px 35.7px 2.3px rgba($BorderBlack, 0.30);
            }
            a {
                width: 216px;
                font-size: .875rem;
                font-family: Montserrat-ExtraBold;
                line-height: normal;
                font-weight: 700;
                color: #2d2829;
                text-decoration: none;
                cursor: pointer;
                border-radius: 4px;
                color: #fff;
                border: 2px solid #181718;
                padding: 10px;
                margin-top: 5px;
                background-color: $CeriseRed;
            }
            a:hover{
                background-color: darken($CeriseRed,$darkerPorcentaje);
            }
            label {
                width: 216px;
                @include textBasisBold($text);
            }
            p {
                width: 216px;
                @include textBasis($text);
            }
            .img-wrapper{
                height: 70px;
                width: 100%;
                img{
                    height: 100%;
                }
            }
        }
    }
}

@include media-max-lg {
    .what-do-we-do {
        height: 700px; // Para alineacion 3-1
    }
}
@include media-md {
    .what-do-we-do {
        border-bottom: unset;
        border-top: unset;
        height: 1050px;
        background-color: $White;
        .title {
            :nth-child(2) {
                width: 100%;
                padding: 0 20px;
                align-self: center;
            }
        }
        .cards-wrapper {
            border-bottom: solid $BorderWidth $BorderBlack;;
            border-top: solid $BorderWidth $BorderBlack;;
            flex-direction: row;
            align-items: center;
            background-color: $AmarilloBanner;
            padding-top: $mainSMRightLeftPadding;
            padding-bottom: 61px;
            width: 100%;
            .card {
                justify-content: space-around;
                width: 382px;
                padding: 43px;
                label {
                    width: 100%;
                    font-size: 1.5rem;
                }
                a {
                    width:100%;
                }
                p {
                    width: 100%;
                }
            }
        }
    }
}

@include media-sm {
    .what-do-we-do {

        .cards-wrapper {
            margin-top: 20px;
            flex-direction: row;
            align-items: center;
            background-color: $AmarilloBanner;
            padding-top: $paddingCajasSM;
            padding-bottom: $paddingCajasSM;
            width: 100%;
            .card {
                max-width: 382px;
                width: 100%;
                margin:  0 $paddingCajasSM;
                height: 150px;
                label {
                    width: 295px;
                    font-size: 1.5rem;
                }
                a {
                    width: 100%;
                    margin:  10px $paddingCajasSM;;

                }
            }
        }
    }
}

@keyframes UpSequence {
    0% {
        opacity: 0;
        margin-top: 80px;
	}
	100% {
        opacity: 1;
        margin-top: 0;
	}
}