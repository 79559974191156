@import "../../styles/main";
@import "../../styles/variables";
@import "../../styles/mixin";

.digging {
    height: 350px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-evenly;
    .content{
        position: relative;
        .img{
            left: -140px;
            top: -6px;
            position: absolute;
            img{
                width: 400px;
            }
        }
        max-width: 800px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        padding: 30px;
        border-radius: 10px;
        border: solid 4px #000;
        background-color: $aqua;
        .title{
            font-family: Montserrat;
            font-size: 30px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #2d2829;
            padding-bottom: 30px;
            padding-left: 15rem;
        }
        .text{
            font-family: Montserrat;
            font-size: 0.875rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #2d2829;
            padding-left: 15rem;
        }
    }
}

@include media-lg {
    .digging {
        height: 100%;
        margin: 60px 30px 0 30px;
        .content{
            .img{
                display: none;
            }
            width: 80%;
            .title {
                padding-left: 0;
            }
            .text{
                padding: 0 1rem;
            }
        }
    }
}
