@import "../../styles/variables";
@import "../../styles/mixin";
.container-slider {
    height: 250px;
    width: 900px;
    position: relative;
    display: flex;
    flex-direction: column;
    
    .slide {
        min-height: 120px;
        border: solid  $BorderWidth $BorderBlack;
        display: flex;
        flex-direction: column;
        width: 740px;
        position: absolute;
        align-self: center;
        opacity: 0;
        transition: opacity ease-in-out 0.2s;
        gap: 12px;
        background-color: $White;
        border-radius: $borderRadiusMain;
        padding: $paddingCajasSM;
        box-shadow: 0px 23px 35.7px 2.3px rgba(57, 58, 57, 0.15);
        @include textBasis($text);
        font-style: italic;
        text-align: center;
        margin-left: 25.5px;
        margin-right: 25.5px;
        .firm {
            display: flex;
            justify-content: flex-end;
        }
        .text-say,.firm{
            font-family: Montserrat;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: italic;
            line-height: normal;
            letter-spacing: normal;
            color: #2d2829;
        } 
    }
    
    .active-anim {
        opacity: 1;
    }
        
    .btn-slide {
        width: 19px;
        height: 29px;
        background-color: transparent;
        border: none;
        position: absolute;
        cursor: pointer;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    .prev {
        cursor: pointer;
        top: 30%;
        left: 20px;
        //transform: translateY(-60%);
    }
    .next {
        cursor: pointer;
        top: 30%;
        right: 20px;
        //transform: translateY(-60%);
    }

    .container-dots {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        
        .dot {
            width: 20px;
            height: 20px;
            border-radius: $borderRadiusCircle-25;
            border: 3px solid #f1f1f1;
            margin: 0 5px;
            background: #f1f1f1;
        }
        .dot.active {
            background: rgb(32, 32, 32);
        }
    }

  }

  @include media-md {

    .container-slider{
        width: 100%;
        .slide{
            width: auto;;
            height: auto;
            max-height: 299px;
        }
    }
    .btn-slide {
        width: 0;
        height: 0;
        visibility: hidden;
    }
  }
  
  @include media-sm {
      
    .container-slider{
        height: 220%;
        .slide{
            width: auto;;
            height: auto;
            max-height: 370px;
        }
    }
  }