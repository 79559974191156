@import "./variables";


@mixin textBasisXL($size) {
    font-size: $size;
    font-family: Montserrat-ExtraBold;
    line-height: normal;
    letter-spacing: 2vw;
    color: $PineTree;
}

@mixin textBasisBold($size) {
    font-size: $size;
    font-family: Montserrat-ExtraBold;
    line-height: normal;
    font-weight: 700;
    color: $PineTree;
}

@mixin textBasis($size) {
    font-size: $size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $PineTree;
}

@mixin textBasisLighter($size) {
    font-size: $size;
    font-weight: lighter;
    font-stretch: lighter;
    font-style: lighter;
    line-height: lighter;
    letter-spacing: lighter;
    color: $PineTree;
}


@mixin transition-ease {
    transition:all 0.5s ease-in-out;
}
@mixin transition-ease-2s {
    transition:all 0.2s ease-in-out;
}
@function set-text-color($color){
    @if (lightness($color)>40%){
        @return #000;
    }
    @else {
        @return #fff;
    }
}

@mixin media-sm{
    @media screen and (max-width:480px){
        @content;
    }
}

@mixin media-md{
    @media screen and (max-width:768px){
        @content;
    }
}
@mixin media-lg{
    @media screen and (max-width:1024px){
        @content;
    }
}
@mixin media-max-lg{
    @media screen and (max-width:1195px){
        @content;
    }
}
@mixin media-xl{
    @media screen and (max-width:1600px){
        @content;
    }
}