@import "./styles/variables";

.app {
  height: 100%;
  background-color: $White;
  
  .grid {
    //padding-top: 93px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}
