@import "../../styles/variables";
@import "../../styles/mixin";

.client-say {
    display: flex;
    flex-direction: row;
    background-color: $AmarilloBanner;
    justify-content: center;
    align-items: center;
    gap: $spaceBetween20;
    height: 200px;
    border-bottom: solid $BorderWidth $BorderBlack;
    border-top: solid $BorderWidth $BorderBlack;
    .label-wrapper {
        width: 180px;
        display: flex;
        label {
            @include textBasisBold($title);
            text-align: center;
            width: 287px;
        }
    }
    .right-container {
        padding-top: 150px;
    }
}

@include media-md {
        
    .client-say {
        border: unset;
        gap:30px;
        background-color: $White;
        height: auto;
        flex-direction: column;
        .right-container {
            border-bottom: solid $BorderWidth $BorderBlack;
            border-top: solid $BorderWidth $BorderBlack;
            flex-direction: row;
            background-color: $AmarilloBanner; 
            padding: 40px 25px 0 25px;
            height: 265px;
            width: 100%;
            }  
            .card{
                margin-top: 95px;
                padding: 0;
            }     
        }

}
