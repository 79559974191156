
html{
  font-size: 16px
}
body,
html,
#root{
  height: auto;
}

#container{
  margin-top: 60px;
}
body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Black";
  font-display: swap;
  src: url("./assets/fonts/Montserrat-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  font-display: swap;
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  font-display: swap;
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-italic";
  font-display: swap;
  src: url("./assets/fonts/Montserrat-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  font-display: swap;
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

