@import "../../styles/main";
//@import "../../styles/mixin";

.welcome {
    display: flex;
    justify-content: space-evenly;
    .textwrapper {
        max-width: 40rem;
        transition: left 0.5s;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        padding: 0 30px 0 30px;
        animation: showUp 0.5s linear forwards;
        
        .title {
            @include textBasisBold($pageTitle);
            padding-bottom: 18px;
            text-align: center;
        }
        .subtitle {
            @include textBasis($pageSubtitle);
            text-align: center;
        }
        label{
            align-self: center;
            max-width: 517px;
        }
        transition-delay: 15s;
    }
    .img {
        max-width: 25rem;
        min-width: 25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            animation: showLeft 0.8s linear forwards;
            justify-content: flex-end;
            width: 100%;
        }        
    }
}

@include media-xl {
    .welcome {
        width: unset;
        .textwrapper{
            width: auto;
            min-height: 50%;
            align-self: center;
            label{
                align-self: unset;
                max-width: 100%;
            }
        }
        .img-wrapper{
            max-width: 45rem;
            min-width: 35rem;
            width: auto;
        }
    }
}

//max-width:768px
@include media-lg {
    .welcome {
        padding-top: 50px;
        background-image: url("../../assets/img/welcome.png");    
        background-repeat: no-repeat;
        background-position: right;
        .textwrapper {
            border: solid $BorderWidth $BorderBlack;
            background-color: white;
            //height: 50%;
            margin: 30px;
            padding: 30px;
            border-radius: $borderRadiusMain;
            box-shadow: 0px 23px 35.7px 2.3px rgba(57, 58, 57, 0.15);
        }

        .img{
            width: 0;
            max-width: 0;
            min-width: 0;
            visibility: hidden;
        }
    }
}

@include media-md{
    .welcome{
        align-self: center;
        .textwrapper{
            min-height: 60%;
            .title{
                @include textBasis(2.5rem)
            }
            
        }
    }
}


// @include media-sm{
//     .technologies-changes{
//         .textwrapper{
//             height: 100%;
//         }
//     }
// }


@keyframes showLeft {
    0% {
        opacity: 0;
        margin-left: 80px;
	}
	100% {
        opacity: 1;
        margin-left: 0;
	}
}
