@import "../../styles/variables";
@import "../../styles/mixin";

.footer {
    border-top: solid $BorderWidth $BorderBlack;
    padding-top: $spaceBetween;
    background-color: $DarkLavender;
    display: flex;
    justify-content: space-around;
    color: $White;
    gap:$spaceBetween45;
    padding: 30px;
    .img-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
        width: 250px;
        img{
            width: 100%;
        }
    }
    .text-wrapper {
        display: flex;
        column-gap: 60px;
        align-items: flex-start;
        div:first-child {
            line-height: 1.9;
            a{
                text-decoration: none;
                color: white;
            }
        }
        div:nth-child(2) {
            line-height: 1.9;
            a{
                text-decoration: none;
                color: white;
            }
        }
        div:nth-child(3) {
            line-height: 1.62;
            a{
                text-decoration: none;
                color: white;
            }
        }
        div:nth-child(4) {
            line-height: 1.66;
            img{
                margin-top: 25px;
            }
        }
        .locate{
            .main-text{
                display: flex;
                flex-direction: column;
            }
        }
        .social-media{
            display: flex;
            gap: 7px;
            svg{
                color: $White;
                width: 35px;
                height: 35px;
            }
        }
    }
    .column {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    .title {
        @include textBasisBold($text);
        color: white !important;
        text-transform: uppercase;
    }
}

@include media-md {
    .footer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .img-wrapper{
            width: 100%;
            padding: 0 40px 0 40px;
            display: flex;
            gap:20px
        }
        .text-wrapper{
            gap: 0;
            .column {
                &.company{
                    width: 0;
                    height: 0;
                    visibility: hidden;
                }
                &.experiences{
                    width: 0;
                    height: 0;
                    visibility: hidden;
                }
                &.connect{
                    width: 0;
                    height: 0;
                    visibility: hidden;
                }
                &.locate{
                    width: 100%;
                    margin-top: 15px;
                    gap: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                }
            }
            .clutch-wrapper{
                width: 135px;
            }
        }
    }
}
