.no-select{
    user-select: none;   
}

button {
    @include textBasisBold($text);
    height: $btnLgHeight;
    text-decoration: none;
    border: none;
    cursor: pointer;
    border-radius: $btnBorderRadius;
    color: $White;
    border: solid  $BorderWidth $BorderBlack;
    padding: 5px 15px;
}

@include media-md{
    button{
        @include textBasisBold($text);
        height: $btnMdHeight;
        color: $White;

    }
}

@keyframes showUp {
    0% {
        opacity: 0;
        margin-top: 80px;
	}
	100% {
        opacity: 1;
        margin-top: 0;
	}
}